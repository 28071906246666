export const getters = {
  items: (state) => state.items,
  item: (state) => (id) => {
    if (id) {
      return state.items.find((item) => item.id === id) || {};
    }
    return {};
  },
  total: (state) => state.total,
  currentPage: (state) => state.currentPage,
  filters: (state) => state.filters,
  step: (state) => (id) => {
    if (id) {
      return state.steps[id] ? state.steps[id] : {};
    }
    return {};
  },
  stepChild: (state) => (id) => {
    if (id) {
      return state.steps[id] ? state.steps[id] : [];
    }
    return [];
  },
  currentChatBot(state) {
    return state.chatbot || {};
  },
  whatsAppChannel(state, getters, rootState) {
    const { companies, currentCompanyID } = rootState.shared;
    const company = (companies?.fullCompanies || companies)
      .find(({ id }) => id === currentCompanyID) || null;

    const campaign = company?.campaigns?.find(({ id }) => id === state.chatbot?.entityId) || null;

    return campaign?.channels?.find(({ channelType }) => channelType.key === 'whatsapp');
  },
};
